<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="refSupplierEdit" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="updateProvedor">          
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar Proveedor</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" text type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar cambios</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Nombre del proveedor*" prepend-icon="fas fa-user"
                                        required clearable v-model="proveedor.nombre"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            
                                <v-col cols="12" sm="6" md="8">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Razon Social*" prepend-icon="fas fa-id-card" required clearable 
                                        v-model="proveedor.razonSocial" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field label="Direccion" hint="Direccion del proveedor"  prepend-icon="fas fa-map-marker-alt"
                                    persistent-hint clearable v-model="proveedor.direccion"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="3">
                                    <ValidationProvider rules="required|min:12|max:13" v-slot="{ errors, valid}" :immediate="true">
                                        <v-text-field label="RFC*" hint="Registro federal de contribuyentes"  prepend-icon="fas fa-qrcode"
                                        persistent-hint clearable required v-model="proveedor.rfc"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <ValidationProvider rules="required|numeric|min:10|max:13" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field type="tel" label="Telefono*" prepend-icon="fas fa-phone"
                                        v-model="proveedor.telefono" :counter="13" hint="Campo opcional"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="9">
                                    <v-textarea class="mx-2" label="Descripcion adicional" rows="1" hint="Informacion extra del proveedor.."
                                    prepend-icon="fas fa-comment" persistent-hint clearable v-model="proveedor.observaciones"></v-textarea>
                                </v-col>
                                <v-col cols="12" class="mt-n5">
                                    <v-checkbox v-model="proveedor.hasCredito" label="¿Tiene credito?" color="success" hide-details></v-checkbox>
                                </v-col>
                                <template v-if="proveedor.hasCredito">
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Dias de credito" type="number" prepend-icon="fas fa-calendar-alt"
                                            v-model="proveedor.diasCredito" min="1"
                                            hint="Escribir los dias de credito del proveedor" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Numero de documento" type="number" prepend-icon="fas fa-file-invoice"
                                            v-model="proveedor.documentoCredito" hint="Cuantos documentos / ventas tiene el proveedor para el credito"
                                            :error-messages="errors" :success="valid" min="1">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" sm="12" md="4">
                                        <ValidationProvider rules="required|decimal|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Limite de credito" type="number" prepend-icon="fas fa-dollar-sign"
                                            v-model="proveedor.limiteCredito" min="1"
                                            hint="Escribir el limite de credito del proveedor" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </template>
                            </v-row>
                            <small>* Indica son campos requeridos</small>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ProveedorDataService from '../../service/catalogo/proveedor/proveedorDataService';
import UbicacionDataService from '../../service/catalogo/ubicacion/ubicacionDataService';
export default {
    name: "EditarProveedor",
    props: {
        dialog: { default: false },
        info: { type: Object, required: true, default: {} },
        handleModal: { type: Function },
        initialize: { type: Function }
    },
    data () {
        return {
            submitted:  false,
            proveedor:  this.info,
            paises:     [],
            estados:    [],
            municipios: []
        }
    },
    methods: {
        getProveedor(idProveedor){
            ProveedorDataService.jsonProveedor(idProveedor).then( resp => {
                this.proveedor = resp.data;
            }).catch(error => {
                this.$swal.fire({ icon: 'error', title: 'No se pudo cargar', html: error.response.data, showConfirmButton: false, timer: 1500 });
            });
        },
        updateProvedor(){
            this.$refs.refSupplierEdit.validate().then( isValid => {
                if( isValid ){
                    ProveedorDataService.update( this.proveedor ).then( rsp => {
                        this.initialize();
                        this.handleModal();
                        this.$swal.fire({  icon: 'success', title: 'Proveedor actualizado correctamente', text: 'Proveedor actualizado correctamente', showConfirmButton: false, timer: 1500 })
                    }).catch( err => {
                        this.$swal.fire({  icon: 'error', title: 'Error al actualizar el proveedor', html: err.response.data, showConfirmButton: false, timer: 1500 })
                    });
                }
            }).catch( err => {
                this.$swal.fire({  icon: 'error', title: 'Error al actualizar el proveedor', text: err, showConfirmButton: false, timer: 1500 });
            });
        },
        async getPaises(){
            this.paises = await UbicacionDataService.getAllPais().then( resp => resp.data.rows ) || [];
        },
        async getEstados( id ){
            this.estados = await UbicacionDataService.getEstadoByIdPais( id ).then( resp => resp.data.rows ) || [];
        },
        async getMunicipios( id ){
            this.municipios = await UbicacionDataService.getMunicipioByIdEstado( id ).then( resp => resp.data.rows ) || [];
        }
    }
}
</script>