<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="cyan darken-4" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo proveedor
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-btn small dark color="cyan darken-4"
                            @click="showInactive = !showInactive">
                            <v-icon left>fa fa-users</v-icon>{{ showInactive ? 'Proveedores Inactivos' : 'Proveedores activos' }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="6" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar proveedor" single-inline hide-details
                        placeholder="Buscar por nombre, RFC, razonSocial, direccion o telefono"
                        @keydown.enter="searchProveedor">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="filterByStatus" sort-by="id" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalSupplier">
                    <template v-slot:[`item.actions`]="{ item }">
                        <ActionsProveedor :item="item" :editProvider="show"
                        :removeProvider="removeProveedor">
                        </ActionsProveedor>
                    </template>
                    <template v-slot:[`item.eliminado`]="{ item }">
                        <v-chip :color="colorStatus(item.eliminado)" label text-color="white" dark x-small filled
                        v-text="item.eliminado ? 'Inactivo' : 'Activo'">
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <ModalNuevoProveedor :dialog.sync="dialog" :initialize.sync="initTableProveedor" :handleModal="handleModal" v-if="dialog"/>
        <ModalEditarProveedor :dialog.sync="dialogEdit" :initialize.sync="initTableProveedor" :info="infoProveedor" :handleModal="handleModalEdit" v-if="dialogEdit"/>
    </v-row>
</template>
<script>
import ProveedorDataService from "@/service/catalogo/proveedor/proveedorDataService";
import ModalNuevoProveedor from "./NuevoProveedor.vue";
import ModalEditarProveedor from "./EditarProveedor.vue";
import { getParamsPagination } from "@/utils/pagination";
import ActionsProveedor from "./ActionsProveedor.vue";
import Columns from "@/components/shared/Columns.vue";
export default {
    name: "ListaProveedor",
    components: { ModalNuevoProveedor, ModalEditarProveedor, ActionsProveedor, Columns },
    created() {
        this.initTableProveedor();
    },
    data() {
        return {
            suppliers: [],
            options: {},
            totalSupplier: 0,
            loading: false,
            search: "",

            showInactive: false,
            dialog: false,
            dialogEdit: false,
            infoProveedor: {},
            headers: [
                { text: "Acciones", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "id", visible: false, groupable: false, show: false },
                { text: "Nombre", value: "nombre", sortable: false, groupable: false, show: true },
                { text: "Razon Social", value: "razonSocial", sortable: false, groupable: false, show: true },
                { text: "Direccion (g)", value: "direccion", sortable: false, groupable: false, show: true },
                { text: 'Telefono', value: 'telefono', sortable: false, groupable: false, show: true },
                { text: "RFC", value: "rfc", sortable: false, groupable: false, show: true },
                { text: "Estatus (g)", value: "eliminado", sortable: false, groupable: false, show: true },
            ]
        }
    },
    methods: {
        searchProveedor() {
            this.options.page = 1;
            this.initTableProveedor(this.options);
        },
        initTableProveedor( options = {} ) {
            this.loading = true;
            const params = getParamsPagination({options, search: this.search });
            ProveedorDataService.getAll(params).then( resp => {
                const { rows, totalElements } = resp.data;
                this.suppliers = rows;
                this.totalSupplier = totalElements;
            }).catch( err => {
                this.$swal.fire({  icon: 'error', html: err.response.data, showConfirmButton: false, timer: 2500 });
            }).finally( () => {
                this.loading = false;
            })
        },
        show( item ) {
            this.infoProveedor = item;
            this.dialogEdit = true;
        },
        removeProveedor( { idProveedor } ) {
            this.$swal({
                title: "Estas seguro que deseas eliminar este proveedor?",
                text: "Esta accion no se podra revertir!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!",
                cancelButtonText: "Cancelar"
            }).then( result => {
                if (result.isConfirmed) {
                    ProveedorDataService.delete(idProveedor).then( _ => {
                        this.initTableProveedor();
                        this.$swal.fire({ icon: 'success', title: 'Proveedor eliminado', showConfirmButton: false, timer: 1400 });
                    }).catch( err => {
                        this.$swal.fire({  icon: 'error', title: 'No se pudo eliminar', html: err.response.data, showConfirmButton: false, timer: 1100 });
                    });
                }
            });
        },
        restore( { idProveedor }) {
            this.$swal.fire({
                title: "Reactivar Proveedor?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, reactivar!",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.isConfirmed) {
                    ProveedorDataService.restore(idProveedor).then( _ => {
                        this.initTableProveedor();
                        this.$swal.fire({ icon: 'success', title: 'Proveedor reactivado', showConfirmButton: false, timer: 1200 });
                    })
                }
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', html: err.response.data, showConfirmButton: false, timer: 2200 })
            });
        },
        colorStatus(status) { return status ? 'red' : 'green'; },
        handleModal() { this.dialog = !this.dialog; },
        handleModalEdit() {
            this.dialogEdit = !this.dialogEdit;
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableProveedor(val);
                }
            }, deep: true
        }
    },
    computed: {
        filterByStatus() {
            return this.showInactive 
            ? this.suppliers.filter(p => p.eliminado) 
            : this.suppliers.filter(p => !p.eliminado);
        },
        filteredHeaders() { return this.headers.filter(h => h.show); }
    }
};
</script>